




































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'
import PageHeader from '@/components/PageHeader.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import can from '@/shared/helpers/can'
import { Permission } from '@/shared/configs/permission.config'
import Form from '@/components/form/Form.vue'
import http from '@/shared/http'
import ResponseInterface from '@/shared/interfaces/response.interface'
import Parameter from '@/shared/modules/parameter/parameter'
import Rule from '@/shared/modules/rule/rule'
import _ from 'lodash'

@Component({
  components: { PageHeader, Form },
  methods: { can }
})
export default class CreateTestSetDialog extends Vue {
  @Prop() meta!: any
  Permission = Permission
  parameters: Parameter[] = []
  rules: Rule[] = []
  inputParameters: any[] = [
    {
      uuid: null,
      default: null,
      expected: null,
    }
  ]
  inputRules: any[] = [
    {
      uuid: null,
      expected: false,
    }
  ]
  errors: any = []

  form: FormBase = new FormBase()
    .setEndpoint(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/testing-sets`)
    .setFields([
      new Field()
        .setKey('name')
        .setTitle('Name'),
      new Field()
        .setType(FieldType.textArea)
        .setKey('description')
        .setTitle('Description'),
    ])
    .setInjectValues({
      rules: [],
      parameters: [],
    })
    .setSubmit(false)

  async created() {
    await this.loadParameters()
    await this.loadRules()
  }

  async loadParameters() {
    await http.get(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/parameters?per_page=1000`)
      .then((response: ResponseInterface<Parameter>) => {
        this.parameters = response.data.data.map((parameter: Parameter) => parameter)
      })
  }

  async loadRules() {
    await http.get(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/rules?per_page=1000`)
      .then((response: ResponseInterface<Rule>) => {
        this.rules = response.data.data.map((rule: Rule) => rule)
      })
  }

  addInputParameter() {
    this.inputParameters.push({
      uuid: null,
      default: null,
      expected: null,
    })
  }

  addInputRule() {
    this.inputRules.push({
      uuid: null,
      expected: false,
    })
  }

  removeInputParameter(key: number) {
    this.inputParameters = this.inputParameters.filter((value: any, index: number) => index !== key)
  }

  removeInputRule(key: number) {
    this.inputRules = this.inputRules.filter((value: any, index: number) => index !== key)
  }

  async submit() {
    this.setFormData()

    const errors = await this.formRef.submit()
    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  setFormData() {
    this.form.injectValues.parameters = []
    this.form.injectValues.rules = []

    this.inputParameters.filter((item: any) => item.uuid).map((item: any) => {
      this.form.injectValues.parameters.push({
        uuid: item.uuid,
        default: item.default,
        expected: item.expected,
      })
    })

    this.inputRules.filter((item: any) => item.uuid).map((item: any) => {
      this.form.injectValues.rules.push({
        uuid: item.uuid,
        expected: item.expected,
      })
    })
  }

  hasError(path: string) {
    return _.has(this.form.errors, path)
  }

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get formRef(): any {
    return this.$refs.form
  }
}
