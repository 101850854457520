








import { Component, Inject, Vue } from 'vue-property-decorator'
import DataTable from '@/components/data-table/DataTable.vue'
import Calculator from '@/shared/modules/calculator/calculator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import { GlobalActions } from '@/shared/store/global/global.actions'
import DataTableAction from '@/shared/classes/data-table/data-table-action'
import CreateTestSetDialog from '@/components/test-sets/CreateTestSetDialog.vue'
import TestSet from '@/shared/modules/test-set/test-set'
import EditTestSetDialog from '@/components/test-sets/EditTestSetDialog.vue'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'
import RunTestSetDialog from '@/components/test-sets/RunTestSetDialog.vue'

@Component({
  components: { DataTable }
})
export default class TestSets extends Vue {
  @Inject('calculator') getCalculator!: () => Calculator

  table: DataTableBase = new DataTableBase()
    .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/testing-sets`)
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setTitle('Name'),
      new DataTableHeader()
        .setKey('created_at')
        .setTitle('Date'),
      new DataTableHeader()
        .setKey('parameters_count')
        .setTitle('Parameters'),
      new DataTableHeader()
        .setKey('rules_count')
        .setTitle('Rules'),
      new DataTableHeader()
        .setKey('description')
        .setTitle('Description'),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('mdi-play-circle-outline')
        .setTitle('Run Test')
        .setAction((testSet: TestSet) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            maxWidth: 1200,
            component: RunTestSetDialog,
            meta: {
              testSet,
              client: this.selectedClient,
              calculator: this.getCalculator(),
              refresh: () => this.tableRef.refresh(),
            }
          })
        }),
      new DataTableAction()
        .setIcon(require('@/assets/images/edit.svg'))
        .setTitle('Edit')
        .setAction(async (testSet: TestSet) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            maxWidth: 1400,
            component: EditTestSetDialog,
            meta: {
              testSet,
              client: this.selectedClient,
              calculator: this.getCalculator(),
              onSuccess: () => {
                this.tableRef.refresh()

                this.$store.dispatch(GlobalActions.showSnackbar, {
                  type: SnackBarType.success,
                  message: 'Test set was successfully updated'
                })
              }
            }
          })
        }),
    ])

  openAddDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 1400,
      component: CreateTestSetDialog,
      meta: {
        client: this.selectedClient,
        calculator: this.getCalculator(),
        onSuccess: () => {
          this.tableRef.refresh()

          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Test set was successfully created'
          })
        }
      }
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
