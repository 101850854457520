
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import http from '@/shared/http'
import TestSet from '@/shared/modules/test-set/test-set'
import capitalize from '@/shared/helpers/capitalize'
import { GlobalActions } from '@/shared/store/global/global.actions'
import EditTestSetDialog from '@/components/test-sets/EditTestSetDialog.vue'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { PageHeader },
  methods: { capitalize },
})
export default class RunTestSetDialog extends Vue {
  @Prop() meta!: any

  running: boolean = false
  test: any = null
  testSet: TestSet | null = null

  async created() {
    this.testSet = await http.get(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/testing-sets/${ this.meta.testSet.uuid }`)
      .then((response: any) => response.data)
  }

  async run() {
    this.running = true

    this.test = await http.post(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/testing-sets/${ this.meta.testSet.uuid }/test`)
      .then((response: any) => response.data)

    this.running = false
  }

  openEditDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)

    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 1400,
      component: EditTestSetDialog,
      meta: {
        testSet: this.testSet,
        client: this.meta.client,
        calculator: this.meta.calculator,
        onSuccess: () => {
          this.meta.refresh()

          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Test set was successfully updated'
          })
        }
      }
    })
  }
}
